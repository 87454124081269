import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import LandingLayout from "../components/LandingLayout"

/* Landing page structure - allows for language selection before entering the site */

export default () => {
  return (
    <LandingLayout>
      <Helmet>
        <title>Meet MJN - Choose your language</title>
        <body className="home landing" />
      </Helmet>
      <main role="main" className="coming-soon text-center">
        <section className="main-item">
          <span className="main-item__title">Select<br /><strong>your language</strong></span>
          
          <Link to="/home" className="cta -blue select-language" title="English">English</Link>
          <br />
          <Link to="/zh" className="cta -blue select-language" title="中文">中文</Link>
        </section>
      </main>
    </LandingLayout>
  )
}