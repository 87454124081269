import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Header from "./Header"
import MobileOptimised from "./MobileOptimised"

export default ({ children }) => {
  return (
    <div>
      <Helmet>
        <html xmlns="http://www.w3.org/1999/xhtml" lang="en" />
        <meta charSet="utf-8" />
        <title>Meet MJN</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Header />
      {children}
      <MobileOptimised />
    </div>
  )
}